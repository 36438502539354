$(function() {
	function msgSuccess(text) {
		swal({
			title: "",
			text: text,
			type: "success",
			confirmButtonText: "OK",
			confirmButtonColor: "#5db81e"
		});
	}

	function msgError(text) {
		swal({
			title: "",
			text: text,
			type: "error",
			confirmButtonText: "OK",
			confirmButtonColor: "#5db81e"
		});
	}

	/*callback*/
	$(".callbackForm").submit(function() {
		//Change
		var th = $(this);
		var id = th.attr("id");

		$.ajax({
			type: "POST",
			url: "/ajax/callback.php",
			data: th.serialize(),
			cache: false,

			complete: function(data) {
				var str = $.parseJSON(data.responseText);
				if (str.err == 1) {
					msgError(str.text);
				} else {
					msgSuccess(str.text);
					setTimeout(function() {
						th.trigger("reset");
						$.fancybox.close();
					}, 1000);
				}
			}
		});

		return false;
	});
});
